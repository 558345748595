// ** React Imports
import { NavLink } from "react-router-dom"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter, Badge
} from "reactstrap"
import { useEffect, useState } from "react"


// ** Third Party Components
import classnames from "classnames"
import { getUserData } from "@utils"

// ** Reactstrap Imports
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import styled from "styled-components"
import neommonLogo from "@src/assets/images/logo/neomoon.svg"
import {handleMenuIdData} from "../../../../../redux/menuIdSelection"
import { isEmbedded } from "react-device-detect"

const VerticalNavMenuLink = ({item, activeItem}) => {


  const [centeredModal, setCenteredModal] = useState(false) 
  const [clickId, setClickId] = useState('')

  const dispatch = useDispatch()

  const optionSelection = (menuId) => {
    dispatch(handleMenuIdData(menuId))
  }
 // console.log(item)
// ** Conditional Link Tag, if item has newTab or externalLink props use <a> tag else use NavLink
  const LinkTag = item.externalLink ? "a" : NavLink

// ** Hooks
  const userData = getUserData()
  const { t } = useTranslation()

  const handleClick = (item) => {
    optionSelection(item.target.id)
  }

  // useEffect(() => {
  // }, [])

  return (
    <li
      className={classnames({
        "nav-item": !item.children,
        disabled: item.disabled,
        active: item.navLink === activeItem
      })}
    >
      <LinkTag
        className="d-flex align-items-center"
        target={item.newTab ? "_blank" : undefined}
        /*eslint-disable */
        {...(item.externalLink === true
          ? {
              href: item.navLink || "",
            }
          : {
              to: item.navLink || "",
              className: ({ isActive }) => {
                if (isActive && !item.disabled) {
                  return "d-flex align-items-center active";
                }
              },
            })}
        onClick={(e) => {
          if (
            item.navLink.length === 0 ||
            item.navLink === "*" ||
            item.disabled === true
          ) {
            e.preventDefault();
          }
        }}>
        {item.icon}
        <span className="menu-item text-truncate" id={item.id} onClick={handleClick}>{t(item.title)}</span>

        {item.badge && item.badgeText ? (
          <Badge className="ms-auto me-1" color={item.badge} pill>
            {item.badgeText}
          </Badge>
        ) : null}
      </LinkTag>
    </li>
  );
};

export default VerticalNavMenuLink;
