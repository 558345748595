// ** React Imports
import { useEffect, useContext } from "react"
import { Navigate, NavLink } from "react-router-dom"

// ** Icons Imports
import { Disc, X, Circle } from "react-feather"

// ** Custom Components
import Avatar from "@components/avatar"
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"
import RatingReadOnly from '@components/RatingReadOnly'
import { ThemeColors } from '@src/utility/context/ThemeColors'


// ** Config
import themeConfig from "@configs/themeConfig"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"
import { getImageUrl } from "../../../../../utility/Utils"


const VerticalMenuHeader = (props) => {

  const user = getUserData()

  const user_avatar = getImageUrl(user.imageUrl)

  const themeColors = useContext(ThemeColors)


  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover
  } = props

  // ** Vars
  
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (

    <div className="navbar-header">

      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink
            to={"/"}
            className="navbar-brand"
          >
            <span className="brand-logo">
              <img src={themeConfig.app.appLogoImage} alt="logo" />
            </span>
            <span>
              <h2 className="brand-text user-brand-text mb-1">{themeConfig.app.appName}</h2>
            </span>
          </NavLink>
        </li>
      </ul>
      <div className="d-flex">

        <Avatar
          img={user_avatar}
          imgHeight="40"
          imgWidth="40"
          />
        <div>
          <span className="user-name fw-bold m-1">{user.name}</span>
          <span className="user-status ms-1"><RatingReadOnly filledColor={themeColors.colors.warning.main} rating={user.userAvgRating ? user.userAvgRating : 0} /> <span className='ms-1'>{user.ratting}</span></span>
        </div>
        <hr />
      </div>

    </div>
  )
}

export default VerticalMenuHeader
