// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import { localStorageUtils } from '../utility/localStorage'
//import useJwt from '@src/auth/jwt/useJwt'

const initalData = () => {
 
  return {
    fromCurrency : JSON.parse(window.sessionStorage.getItem('j-fromCurrency')),
    toCurrency : JSON.parse(window.sessionStorage.getItem('j-toCurrency')),
    paymentMethod : JSON.parse(window.sessionStorage.getItem('j-paymentMethod')),
    supportTypes : JSON.parse(window.sessionStorage.getItem('j-supportTypes')),
    filterFromCurrency : JSON.parse(window.sessionStorage.getItem('filter-fromCurrency')),
    filterToCurrency: JSON.parse(window.sessionStorage.getItem('filter-toCurrency')),
    filterCurrency: JSON.parse(window.sessionStorage.getItem('filter-Currency'))
  }
  //** Parse stored json or if none return initialValue
  
}
export const searchDetailSelectionSlice = createSlice({
  name: 'searchDetailSelection',
  initialState: {
    searchDetailSelectionData: initalData()
  },
  reducers: {
   
    handleDropdownData:(state, action) => {
      // console.log("state", state)
      // console.log(action.payload)
      window.sessionStorage.setItem('j-fromCurrency', JSON.stringify(action.payload.fromCurrency))
      window.sessionStorage.setItem('j-toCurrency', JSON.stringify(action.payload.toCurrency))
      window.sessionStorage.setItem('j-paymentMethod', JSON.stringify(action.payload.paymentMethod))
      window.sessionStorage.setItem('j-supportTypes', JSON.stringify(action.payload.supportTypes))
      window.sessionStorage.setItem('filter-fromCurrency', JSON.stringify(action.payload.filterFromCurrency))
      window.sessionStorage.setItem('filter-toCurrency', JSON.stringify(action.payload.filterToCurrency))
      window.sessionStorage.setItem('filter-Currency', JSON.stringify(action.payload.filterCurrency))
      state.searchDetailSelectionData.fromCurrency = action.payload.fromCurrency
      state.searchDetailSelectionData.toCurrency = action.payload.toCurrency
      state.searchDetailSelectionData.paymentMethod = action.payload.paymentMethod
      state.searchDetailSelectionData.supportTypes = action.payload.supportTypes
      state.searchDetailSelectionData.filterFromCurrency = action.payload.filterFromCurrency
      state.searchDetailSelectionData.filterToCurrency = action.payload.filterToCurrency
      state.searchDetailSelectionData.filterCurrency = action.payload.filterCurrency

      
    }
  }
})

export const { handleDropdownData } = searchDetailSelectionSlice.actions

export default searchDetailSelectionSlice.reducer
