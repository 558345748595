// ** Third Party Components
import Rating from 'react-rating'
import { Star } from 'react-feather'

// ** Reactstrap Imports
import { Card, CardHeader, CardTitle, CardBody } from 'reactstrap'

const RatingReadOnly = ({ filledColor, dir, rating }) => {
  return (
   
        <Rating
          readonly
          direction={dir}
          initialRating={rating}
          emptySymbol={<Star size={18} fill='#babfc7' stroke='#babfc7' />}
          fullSymbol={<Star size={18} fill={filledColor} stroke={filledColor} />}
        />
     
  )
}

export default RatingReadOnly
