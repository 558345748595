import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import { localStorageUtils } from '../utility/localStorage'
//import useJwt from '@src/auth/jwt/useJwt'

const initalData = () => {
 
  return ""
  //** Parse stored json or if none return initialValue
  
}
export const menuIdSelectionSlice = createSlice({
  name: 'menuIdSelection',
  initialState: {
    menuId: initalData()
  },
  reducers: {
   
    handleMenuIdData:(state, action) => {
    //   console.log(state)
      // console.log(action.payload)
      state.menuId = action.payload
      // console.log(state.menuId)
    }
  }
})

export const { handleMenuIdData } = menuIdSelectionSlice.actions

export default menuIdSelectionSlice.reducer