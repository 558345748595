import { Outlet } from "react-router-dom"
import { useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import ComponentSpinner from "../@core/components/spinner/Loading-spinner"
import toast from "react-hot-toast"
import {showToast} from "../redux/toast"

const BlankLayout = () => {

const loaderState = useSelector(state => state.loader.loaderState)
const showToaster = useSelector((state) => state.toast.setToast)
const dispatch = useDispatch()


  if (showToaster.showToast === true && showToaster.toastType === "success") {
    toast.success(showToaster.toastMessage)

    setTimeout(() => { 
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 0)
    
  } else if (showToaster.showToast === true && showToaster.toastType === 'error') {
    toast.error(showToaster.toastMessage)
    setTimeout(() => { 
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 1000)
  } else {
  }
    return (
        <div>
            <Outlet />
            {(loaderState && loaderState === true) ? <ComponentSpinner /> : ""}
        </div>
    )
  }
  
  export default BlankLayout
  