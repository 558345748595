// ** Reducers Imports
import layout from "./layout"
import navbar from "./navbar"
import auth from './authentication'
import selection from './selection'
import searchDetailSelection from './searchDetailSelection'
import loader from './loader'
import toast from './toast'
import historyFilter from './historyFilterSlice'
import menuIdSelectionData from "./menuIdSelection"
const rootReducer = { navbar, layout, auth, selection, searchDetailSelection, loader, toast, historyFilter, menuIdSelectionData }

export default rootReducer
