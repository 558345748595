// ** React Imports
import { Fragment, useState } from 'react'
import { Zap, Clock } from 'react-feather'
import { Tooltip, Badge } from 'reactstrap'

// ** Custom Components
import NavbarBookmarks from './NavbarBookmarks'
import NavbarUser from './NavbarUser'
import { NavLink, useNavigate } from 'react-router-dom'

const ThemeNavbar = props => {
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  const [satellitetooltipOpen, setSatellitetooltipOpen] = useState(false)
  const [tooltipOpen, setTooltipOpen] = useState(false)


  const navigate = useNavigate()

  const handleZapClick = () => {
    navigate('/admin/satellite_User_Request')
  }
  return (
    <Fragment>
      <div className='bookmark-wrapper d-flex align-items-center me-1'>
        <NavbarBookmarks setMenuVisibility={setMenuVisibility} />
      </div>
      {/* <div className='bookmark-wrapper d-flex align-items-center me-1' onClick={handleZapClick}>
        <Zap color='white' id='Satellite' size={21} />
        <Tooltip
          placement='top'
          isOpen={satellitetooltipOpen}
          target='Satellite'
          toggle={() => setSatellitetooltipOpen(!satellitetooltipOpen)}
        >
          New Satellite Request
        </Tooltip>
      </div> */}
        {/* <Badge pill color='danger' className='New_Satellite_Request'>
          5
        </Badge> */}
      {/* <div className=' d-flex align-items-center'>
        <Clock color='white' id='Recent' />
        <Tooltip
          placement='top'
          isOpen={tooltipOpen}
          target='Recent'
          toggle={() => setTooltipOpen(!tooltipOpen)}
        >
          Recent Transaction
        </Tooltip>
      </div> */}
      <NavbarUser skin={skin} setSkin={setSkin} />
    </Fragment>
  )
}

export default ThemeNavbar
