import { createSlice } from '@reduxjs/toolkit'

const initalData = () => {
    return {
        loaderState: false
    }
}

export const loaderSlice = createSlice({
    name: 'loader',
    initialState: {
        loaderState: initalData()
    },
    reducers: {
        showLoader: (state, action) => {
            state.loaderState = true
        },
        hideLoader: (state, action) => {
            state.loaderState = false
        }
    }

})

export const { showLoader, hideLoader } = loaderSlice.actions


export default loaderSlice.reducer
