import rocket from "../../../assets/images/logo/spinner-rocket.gif"

const ComponentSpinner = () => {
  return (
    <div className="spinner-layer">
        <img className="neo-spinner" src={rocket} alt={""} />
    </div>
)
 
}

export default ComponentSpinner
