// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'
import { useNavigate } from 'react-router-dom'

// ** UseJWT import to get config
import { localStorageUtils, sessionStorageUtils } from '../utility/localStorage'
//import useJwt from '@src/auth/jwt/useJwt'

const initialUser = () => {
  const item = window.localStorage.getItem('userData')
  //** Parse stored json or if none return initialValue
  return item ? JSON.parse(item) : {}
}

export const authSlice = createSlice({
  name: 'authentication',
  initialState: {
    userData: initialUser()
  },
  reducers: {
    handleLogin: (state, action) => {
      state.userData = action.payload
      
      localStorageUtils.setUserSignedInStatus(true)
      localStorageUtils.setUserInfo(action.payload)
      localStorageUtils.setAccessToken(action.payload.firebaseToken)
     
      //state[config.storageRefreshTokenKeyName] = action.payload[config.storageRefreshTokenKeyName]
      localStorage.setItem('userData', JSON.stringify(action.payload))
      //localStorage.setItem(config.storageTokenKeyName, JSON.stringify(action.payload.accessToken))
      //localStorage.setItem(config.storageRefreshTokenKeyName, JSON.stringify(action.payload.refreshToken))
    },
    handleLogout: state => {
      // console.log("LOGOUT")
      state.userData = {}
      //state[config.storageTokenKeyName] = null
      //state[config.storageRefreshTokenKeyName] = null
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem('userData')
      
      localStorageUtils.removeAccessToken()
      // localStorageUtils.removeUserInfo()
      sessionStorageUtils.removeSessionStorageItems()
      localStorageUtils.setUserSignedInStatus(false)
      window.sessionStorage.clear()
      //localStorage.removeItem(config.storageTokenKeyName)
      //localStorage.removeItem(config.storageRefreshTokenKeyName)
    }
  }
})

export const { handleLogin, handleLogout } = authSlice.actions

export default authSlice.reducer
