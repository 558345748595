// ** React Imports
import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

// ** Custom Components
import Avatar from '@components/avatar'

// ** Utils
import { isUserLoggedIn } from '@utils'

// ** Store & Actions
import { useDispatch } from 'react-redux'
import { handleLogout } from '@store/authentication'

// ** Third Party Components
import { User, Mail, CheckSquare, MessageSquare, Settings, CreditCard, HelpCircle, Power } from 'react-feather'

// ** Reactstrap Imports
import { UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem,   Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap'
import neommonLogo from "@src/assets/images/logo/neomoon.svg"

// ** Default Avatar Image
import defaultAvatar from '@src/assets/images/default-img.png'
import { useTranslation } from 'react-i18next'
const UserDropdown = () => {
  // ** Store Vars
  const [centeredModal, setCenteredModal] = useState(false) 

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const Logout = () => {
    dispatch(handleLogout())
    navigate("/admin")
}
const {t} = useTranslation()
const handleCancle = () => {
    setCenteredModal(!centeredModal)
   
}

  useEffect(() => {

  }, [])

  // ** State
  const [userData, setUserData] = useState(null)

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(JSON.parse(localStorage.getItem('userData')))
    }
  }, [])


  //** Vars
  const userAvatar = (userData && userData.avatar) || defaultAvatar

  return (
  <>
   
    <UncontrolledDropdown tag='li' className='dropdown-user nav-item'>
      <DropdownToggle href='/' tag='a' className='nav-link dropdown-user-link' onClick={e => e.preventDefault()}>
        <div className='user-nav d-sm-flex d-none'>
          <span className='user-name fw-bold'>{(userData && userData.name) || 'Jay Patel'}</span>
          <span className='user-status'>{(userData && userData.roleName) || 'Admin'}</span>
        </div>
        <Avatar img={userAvatar} imgHeight='40' imgWidth='40' status='online' />
      </DropdownToggle>
      <DropdownMenu end>
        <DropdownItem tag={Link} to='#'>
          <User size={14} className='me-75' />
          <span className='align-middle'>Profile</span>
        </DropdownItem>

        {/* <DropdownItem tag={Link} to='/apps/email'>
          <Mail size={14} className='me-75' />  
          <span className='align-middle'>Inbox</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/apps/todo'>
          <CheckSquare size={14} className='me-75' />
          <span className='align-middle'>Tasks</span>
        </DropdownItem> */}
        {/* <DropdownItem tag={Link} to='#'>
          <MessageSquare size={14} className='me-75' />
          <span className='align-middle'>Chats</span>
        </DropdownItem> */}

        <DropdownItem divider />
        <DropdownItem tag={Link} to='#'>
          <Settings size={14} className='me-75' />
          <span className='align-middle'>Settings</span>
        </DropdownItem>
        
        {/* <DropdownItem tag={Link} to='/pages/pricing'>
          <CreditCard size={14} className='me-75' />
          <span className='align-middle'>Pricing</span>
        </DropdownItem>
        <DropdownItem tag={Link} to='/pages/faq'>
          <HelpCircle size={14} className='me-75' />
          <span className='align-middle'>FAQ</span>
        </DropdownItem> */}
        
        <DropdownItem onClick={() => setCenteredModal(!centeredModal)}>
          <Power size={14} className='me-75'/>
          <span className='align-middle'>Logout</span>
        </DropdownItem>
        
      </DropdownMenu>

    </UncontrolledDropdown>
    <Modal 
      isOpen={centeredModal}
      toggle={() => setCenteredModal(!centeredModal)}
      className="modal-dialog-centered blue-modal modal-xs all-sweet-alt-model-font-size">
      <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>
      </ModalHeader>
      <ModalBody>
        <div>
      <img className='mx-auto' src={neommonLogo} alt="" />

        </div>
      {t('logout_model_mes')}
      </ModalBody>
      <ModalFooter>
      <Button color="dark" 
          onClick={() => handleCancle()}>
            {t('logout_model_cancel_btn')}
        </Button>{" "}
        <Button
          color="primary"
          onClick={() => Logout()}>
         {t('ok_model_btn')}
        </Button>{" "}
      </ModalFooter>
    </Modal>
   </>

  )
}

export default UserDropdown
