// You can customize the template with the help of this file

//Template config options
const themeConfig = {
  app: {
    appName: "Neomoon",
    adminAppLogoImage: require("@src/assets/images/logo/sidebar-logo-dark.png").default,
    appLogoImage: require("@src/assets/images/logo/logo.png").default,
    appLogoImagedark: require("@src/assets/images/neomoon-login-logo.png").default
  },
  layout: {
    isRTL: false,
    skin: "light", // light, dark, bordered, semi-dark
    type: "vertical", // vertical, horizontal
    contentWidth: "full", // full, boxed
    menu: {
      isHidden: true,
      isCollapsed: false
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: "static", // static , sticky , floating, hidden
      backgroundColor: "white" // BS color options [primary, success, etc]
    },
    footer: {
      type: "sticky" // static, sticky, hidden
    },
    customizer: false,
    scrollTop: false, // Enable scroll to top button
    toastPosition: "top-right" // top-left, top-center, top-right, bottom-left, bottom-center, bottom-right
  }
}

export default themeConfig
