// ** React Imports
import { useEffect, useContext  } from "react"
import { Navigate, NavLink } from "react-router-dom"

// ** Icons Imports
import { Disc, X, Circle } from "react-feather"

// ** Custom Components
import Avatar from "@components/avatar"
import defaultAvatar from "@src/assets/images/portrait/small/avatar-s-11.jpg"
import RatingReadOnly from '@components/RatingReadOnly'
import { ThemeColors } from '@src/utility/context/ThemeColors'


// ** Config
import themeConfig from "@configs/themeConfig"

// ** Utils
import { getUserData, getHomeRouteForLoggedInUser } from "@utils"


const VerticalMenuHeader = (props) => {

  const user = getUserData()
 // const user_avatar = getImageUrl(user.imageUrl)
  
  const themeColors = useContext(ThemeColors)
 
  // ** Props
  const {
    menuCollapsed,
    setMenuCollapsed,
    setMenuVisibility,
    setGroupOpen,
    menuHover
  } = props

  // ** Vars
 
  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    
    <div className="navbar-header admin-navbar-header">
    
      <ul className="nav navbar-nav flex-row">
        <li className="nav-item me-auto">
          <NavLink
            to={user ? getHomeRouteForLoggedInUser("admin") : "/"}
            className="navbar-brand"
          >
            <span className="brand-logo ">
              <img src={themeConfig.app.adminAppLogoImage} alt="logo" />
            </span>
            <h2 className="brand-text jh-admin-brand-text mb-0">{themeConfig.app.appName}</h2>
          </NavLink>
        </li>
        <li>
          <div className="nav-link modern-nav-toggle cursor-pointer">
            <Toggler />
            <X
              onClick={() => setMenuVisibility(false)}
              className="toggle-icon icon-x d-block d-xl-none"
              size={20}
            />
          </div>
        </li>
        </ul>

        {/* <div className="d-flex">
        <Avatar
          img={user_avatar}
          imgHeight="40"
          imgWidth="40"
          status="online" />
          <div>
            <span className="user-name fw-bold m-2">admin</span><br/>
            <small className="user-name  m-2">admin@gmail.com</small>
          </div>
          <hr/>
          </div> */}
        
    </div>
  )
}

export default VerticalMenuHeader
