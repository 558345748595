// ** Dropdowns Imports
import UserDropdown from "./UserDropdown"

// ** Third Party Components
import { Sun, Moon, Menu } from "react-feather"

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap"


const NavbarUser = (props) => {

  const { skin, setSkin, setMenuVisibility } = props

  return (
    <ul className="nav navbar-nav align-items-center">
      {/* <UserDropdown /> */}
      <NavItem className="mobile-menu me-auto">
            <NavLink
              className="nav-menu-main menu-toggle hidden-xs is-active"
              onClick={() => setMenuVisibility(true)}>
              <Menu className="ficon"/>
            </NavLink>
          </NavItem>
    </ul>
  )
}
export default NavbarUser
