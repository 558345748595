// ** React Imports
import { Outlet, useNavigate } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout"

// ** Menu Items Array
import navigation from "@src/navigation/vertical"
import { isUserLoggedIn, getUserData } from "@utils"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ComponentSpinner from "../@core/components/spinner/Loading-spinner"
import toast from "react-hot-toast"
import { localStorageUtils, sessionStorageUtils } from '../utility/localStorage'
import { showToast } from "../redux/toast"
import { info } from "sass"
import { userType } from "../utility/Utils"
import { selectionUserTypes, userRouteAuth, sateliteRouteAuth } from "../AppConstants"

const VerticalLayout = (props) => {
  const user = getUserData()
  const selectedUser = JSON.parse(sessionStorage.getItem("Select-usertype"))
  const roleId = useSelector(state => parseInt(state.selection.selectionData.selectionUserTypes))
  const userLogin = userType.User
  //const currentType = localStorageUtils.getUserRole()

  const userData = useSelector((state) => state.auth.userData)
  const showToaster = useSelector((state) => state.toast.setToast)
  const loaderState = useSelector((state) => state.loader.loaderState)
  let navigationData = navigation

  // ! Remove Admin Invite For satellite request
  // if (parseInt(userData.isSatelliteInviteSent) === 0 && parseInt(user.roleId) === userType.User) {
  //   //Hide My Offers And Satellite_Request
  //   navigationData = navigationData.filter(t => (t.id !== "My_Offers" && t.id !== "Satellite_Request"))
  // } else if (parseInt(userData.isSatelliteInviteSent) === 1 && parseInt(user.roleId) === userType.User) {
  //   navigationData = navigationData.filter(t => t.id !== "My_Offers")
  // }

  if (parseInt(user.roleId) === userType.User) {
    //Hide My Offers
    navigationData = navigationData.filter(t => (t.id !== "My_Offers" && t.id !== "Propose_Payment_Method"))
  } 

  if (parseInt(user.roleId) === userType.Satellite) {
    navigationData = navigationData.filter(t => t.id !== "Satellite_Request")
  }
  // hide use selecation
  if (selectedUser === selectionUserTypes.satellite) {
    navigationData = navigationData.filter(t => t.id !== "UserFavoriteList")
    navigationData = navigationData.filter(t => t.id !== "home")
  } else if (selectedUser === selectionUserTypes.user) {
    navigationData = navigationData.filter(t => t.id !== "My_Offers")
  }

  const navigate = useNavigate()
  const dispatch = useDispatch()
  // ** For ServerSide navigation

  if (showToaster.showToast === true && showToaster.toastType === "success") {
    toast.success(showToaster.toastMessage)

    setTimeout(() => {
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 0)

  } else if (showToaster.showToast === true && showToaster.toastType === 'error') {
    toast.error(showToaster.toastMessage)
    setTimeout(() => {
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 1000)
  } else {
  }

  const loginRedirection = () => {

    if (isUserLoggedIn()) {
      //User Logged in so we will allow user to go ahead and play with apps
      const satelliteLogin = userType.Satellite
      const userLogin = userType.User
      const token = localStorageUtils.getAccessToken()
      const pathname = window.location.pathname

      const arrsatelite = sateliteRouteAuth
      //   sateliteRouteAuth.find((e) => {
      //     return e === pathname
      // })

      const arruser = userRouteAuth
      //   userRouteAuth.find((e) => {
      //     return e === pathname
      // })

      // if No Token Re-direct To Login Page 
      if (!token) {
        toast.error("Unauthorized Access!")
        navigate("/")
      }

      // Check UserRole For User
      //my offers
      //3
      if (roleId === userLogin) {
        // if (Number(currentType) !== userLogin) { 
        //   toast.error("Unauthorized Access!")
        //   navigate("/not-authorized")
        // }  
        if (arruser.includes(pathname)) {
          toast.error("Unauthorized Access!")
          navigate("/not-authorized")
        }
        

        // Check UserRole For Satellite
      } else if (roleId === satelliteLogin) {
        //   if (Number(currentType) !== satelliteLogin) {
        //   toast.error("Unauthorized Access!")
        //   navigate("/not-authorized")
        //  }
        if (arrsatelite.includes(pathname)) {
          toast.error("Unauthorized Access!")
          navigate("/not-authorized")
        }

      }

      
      if (parseInt(user.roleId) === userType.Administrator) {
        
        navigate("/not-authorized")
      }

    } else {
      navigate("/")
    }
  }

  useEffect(() => {
    // console.log("loginRedirection")
    loginRedirection()

  }, [userData])


  return (

    <Layout menuData={navigationData} {...props}>
      {(loaderState && loaderState === true && window.location.pathname !== "/confirmation" && window.location.pathname !== "/chat" && !window.location.pathname.includes("support-chat")) ? <ComponentSpinner /> : ""}
      <Outlet />
    </Layout>

  )
}

export default VerticalLayout
