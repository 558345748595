import { Navigate } from "react-router-dom"
export const localStorageUtils = {
  setUserInfo: (userInfo) => {
    localStorage.setItem("userData", JSON.stringify(userInfo))
  },
  getUserRole: () => {
    return JSON.parse(window.localStorage.getItem('userData')).roleId
  },
  getUserPhone: () => {
    return JSON.parse(window.localStorage.getItem('userData')).phoneNumber
  },
  getUserID: () => {
    return JSON.parse(window.localStorage.getItem('userData')).id
  },
  getUserInfo: () => {
    const userInfoStr = localStorage.getItem("userData")
    let userInfo
    if (userInfoStr) {
      userInfo = JSON.parse(userInfoStr)
    } else {
      userInfo = undefined
    }
    return userInfo
  },
  getUserName: () => {
    return JSON.parse(window.localStorage.getItem('userData')).name
  },
  setAccessToken: (token) => {
    localStorage.setItem("p2p:accessToken", token)
  },
  getAccessToken: () => {
    const token = localStorage.getItem("p2p:accessToken")
    if (token && token !== "") {
      return token
    } else return ""
  },
  removeAccessToken: () => {
    localStorage.removeItem("p2p:accessToken")
  },
  // removeUserInfo : () => {
  //   localStorage.removeItem("p2p:userInfo")
  // },
  setUserType: (user_type) => {
    localStorage.setItem("p2p:userType", JSON.stringify(user_type))
  },
  getUserType: () => {
    const userType = localStorage.getItem("p2p:userType")
    if (userType && userType !== "") {
      return userType
    } else return ""
  },
  setUserSettings: (userSettings) => {
    localStorage.setItem("p2p:userSettings", JSON.stringify(userSettings))
  },
  getUserSettings: () => {
    const userSettings = localStorage.getItem("p2p:userSettings")
    if (userSettings) {
      return userSettings
    } else return null
  },
  setUserSignedInStatus: (isUserSignedIn) => {
    localStorage.setItem("p2p:isUserSignedIn", JSON.stringify(isUserSignedIn))
  },
  getUserSignedInStatus: () => {
    return Boolean(localStorage.getItem("p2p:isUserSignedIn"))
  },
  getUserBalance: () => {
    return JSON.parse(localStorage.getItem('j-userInfo')).walletBalance
  }
}

export const sessionStorageUtils = {
  removeSessionStorageItems: () => {
    sessionStorage.removeItem("avatar_user")
    sessionStorage.removeItem("j-fromCurrency")
    sessionStorage.removeItem("toCurrency")
    sessionStorage.removeItem("FAVORITE")
    sessionStorage.removeItem("user_uid")
    sessionStorage.removeItem("amount")
    sessionStorage.removeItem("fromCurrency")
    sessionStorage.removeItem("j-paymentMethod")
    sessionStorage.removeItem("j-toCurrency")
    sessionStorage.removeItem("userEmail")
    sessionStorage.removeItem("paymentMethod")
    sessionStorage.removeItem("Select-option")
    sessionStorage.removeItem("search-params")
  },
  getSupportTypes: () => {
    return JSON.parse(window.sessionStorage.getItem('j-supportTypes'))
  },
  // getUserDOB: () => {
  //   return JSON.parse(window.localStorage.getItem('p2p:userInfo')).dateOfBirth
  // },
  getUserBirthday: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).birthday
  },
  getUserDocId: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).docId
  },
  getUserBalance: () => {
    return JSON.parse(window.sessionStorage.getItem('j-userInfo')).walletBalance
  },
  getUserSecurId: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).securityId
  },
  getUserStyle: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).style
  },
  getUserEpyt: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).epyt
  },
  getUserQuestion: () => {
    return JSON.parse(window.sessionStorage.getItem('search-params')).question
  },
  getUserEmail: () => {
    return JSON.parse(window.sessionStorage.getItem('userEmail')).userName
  },
  getFromSymbol: () => {
    return JSON.parse(window.sessionStorage.getItem('fromCurrency')).fromsybmol
  },
  getToSymbol: () => {
    return JSON.parse(window.sessionStorage.getItem('toCurrency')).Symbol
  },
  getClickedUserType: () => {
    return JSON.parse(window.sessionStorage.getItem('Select-usertype'))
  },
  setFavorite: (favorite) => {
    window.sessionStorage.setItem('FAVORITE', JSON.stringify(favorite))
  },
  getUserAvatar: () => {
    return JSON.parse(window.sessionStorage.getItem('avatar_user'))
  },
  getFavorite: () => {
    return JSON.parse(window.sessionStorage.getItem('FAVORITE'))
  },
  getDefaultPaymentValue: () => {
    return JSON.parse(window.sessionStorage.getItem('paymentMethod'))
  },
  getAmount: () => {
    return JSON.parse(window.sessionStorage.getItem('amount'))
  },
  userRatting: () => {
    return JSON.parse(window.sessionStorage.getItem('j-userInfo')).userAvgRating
  },
  getUserUid: () => {
    return JSON.parse(window.sessionStorage.getItem('user_uid'))
  },
  setSearchParams: (details) => {
    window.sessionStorage.setItem('search-params', JSON.stringify(details))
  },
  setUserEmail: (payload) => {
    window.sessionStorage.setItem('userEmail', JSON.stringify(payload))
  },
  setUserUid: (userUid) => {
    window.sessionStorage.setItem('user_uid', JSON.stringify(userUid))
  },
  setUserAvatar: (avatar_user) => {
    window.sessionStorage.setItem('avatar_user', JSON.stringify(avatar_user))
  }
}