// ** React Imports
import { Fragment, lazy, useState, useNavigate } from "react"
import { Navigate } from "react-router-dom"
// ** Layouts
import BlankLayout from "@src/layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import Adminlayout from "../../layouts/AdminLayout"
import LayoutWrapper from "@src/@core/layouts/components/layout-wrapper"
// import LoginLayout from "@src/@core/layout/components/login"

// ** Route Components
import PublicRoute from "@components/routes/PublicRoute"
import PrivateRoute from "@components/routes/PrivateRoute"

// ** Utils
import { isObjEmpty } from "@utils"
import { info } from "sass"
import { hide } from "@popperjs/core"

const getLayout = {
  blank: <BlankLayout />,
  vertical: <VerticalLayout />,
  horizontal: <HorizontalLayout />,
  admin: <Adminlayout/>
}

// ** Document title
const TemplateTitle = "%s - P2P Neomoon"

// ** Default Route
const DefaultRoute = "/home/filter"

const Home = lazy(() => import("../../views/Home"))
const Searchamount = lazy(() => import("../../views/searchamount"))
const SecondPage = lazy(() => import("../../views/SecondPage"))
const Login = lazy(() => import("../../views/Login"))
const Register = lazy(() => import("../../views/Register"))
const ForgotPassword = lazy(() => import("../../views/ForgotPassword"))
const Error = lazy(() => import("../../views/Error"))
const SearchResult = lazy(() => import("../../views/SearchResult"))
const SearchDetails = lazy(() => import("../../views/SearchDetails"))
const SearchPreview = lazy(() => import("../../views/SearchPreview"))
const Confirmation = lazy(() => import("../../views/Confirmation"))
const Success = lazy(() => import("../../views/Success"))
const UserRating = lazy(() => import("../../views/UserRating"))
const TransactionHistory = lazy(() => import("../../views/TransactionHistory"))
const Desicionpage = lazy(() => import("../../views/Desicionpage"))
const Chat = lazy(() => import("../../views/AppChat"))
const SupportChat = lazy(() => import("../../views/userSupportChat"))
const TransactionAccept = lazy(() => import("../../views/TransactionAccept"))
const Logout = lazy(() => import("../../views/logout"))
const LoginValidateEmail = lazy(() => import("../../views/login/loginValidateEmail"))
const LoginValidateDob = lazy(() => import("../../views/login/loginValidateDob"))
const LoginValidateAuth = lazy(() => import("../../views/login/loginValidateAuth"))
const LoginValidatePassword = lazy(() => import("../../views/login/loginValidatePassword"))
const LoginValidateResponse = lazy(() => import("../../views/login/loginValidateResponse"))
const MyOffers = lazy(() => import("../../views/MyOffers"))
const SatelliteRequest = lazy(() => import("../../views/SatelliteRequest"))
const ProposePaymentMethod = lazy(() => import("../../views/ProposePaymentMethod"))
const MyAccount = lazy(() => import("../../views/MyAccount"))
const Language = lazy(() => import("../../views/Language"))

const CurrencyTable = lazy(() => import("../../views/admin/currencyMaster/CurrencyTable"))
const CurrencyAdd = lazy(() => import("../../views/admin/currencyMaster/CurrencyAdd"))
const CurrencyEdit = lazy(() => import("../../views/admin/currencyMaster/CurrencyEdit"))
const CountryList = lazy(() => import("../../views/admin/CountryMaster/CountyList"))
const CountryAdd = lazy(() => import("../../views/admin/CountryMaster/CountryAdd"))
const Notauthorized = lazy(() => import("../../views/NotAuthorized"))
const CountryEdit = lazy(() => import("../../views/admin/CountryMaster/CountryEdit"))
const SatelliteUserList = lazy(() => import("../../views/admin/SatelliteUser/SatelliteUserList"))
const PaymentMethodList = lazy(() => import("../../views/admin/PaymentMethod/PaymentMethodList"))
const PaymentMethodAdd = lazy(() => import("../../views/admin/PaymentMethod/PaymentMethodAdd"))
const PaymentMethodEdit = lazy(() => import("../../views/admin/PaymentMethod/PaymentMethodEdit"))
const Admindashboard = lazy(() => import("../../views/Dashboard/index"))
const TradeHistory = lazy(() => import("../../views/admin/Trade History/TradeHistory.js"))
const UserManagement = lazy(() => import("../../views/admin/UserManagement/UserManage.js"))
const UserDetails = lazy(() => import("../../views/admin/UserManagement/UserDetails"))
const TradeDetails = lazy(() => import("../../views/admin/TradeDetails/TradeDetails.js"))
const AdminSupportDetails = lazy(() => import("../../views/admin/AdminSupport/AdminSupportDetail"))
const AdminSupportList = lazy(() => import("../../views/admin/AdminSupport/AdminSupportList"))
const RecentTransaction = lazy(() => import("../../views/admin/RecentTransaction/RecentTransaction"))
const UserNotification = lazy(() => import("../../views/UserNotification"))
const Notification = lazy(() => import("../../views/admin/Notication/Notification"))
const UserFavoriteList = lazy(() => import("../../views/UserFavoriteList.js"))
const UserSuppot = lazy(() => import("../../views/SupportPage.js"))
const NotificationHistory = lazy(() => import("../../views/admin/Notication/NotificationHistory"))
const ProposedPayment = lazy(() => import("../../views/admin/ProposedPayments/ProposedPayments"))
const SystemConfigation = lazy(() => import("../../views/admin/systemConfig/systemconfig"))
const AdminPagesAdd = lazy(() => import("../../views/admin/Admin_Pages/AdminPagesAdd"))
const AdminPageList = lazy(() => import("../../views/admin/Admin_Pages/AdminpageList"))
const AdminpageEdit = lazy(() => import("../../views/admin/Admin_Pages/AdminPageEdit"))
const HomePageFilter = lazy(() => import("../../views/HomePageFilter"))
const MyOfferesNew = lazy(() => import("../../views/MyOffer1"))
const SatelliteReports = lazy(() => import("../../views/admin/reports/SatelliteReports"))
const CureencyReports = lazy(() => import("../../views/admin/reports/CurrencyReports"))
const PaymentMethodReports = lazy(() => import("../../views/admin/reports/PaymentMethodReports"))
const UserReports = lazy(() => import("../../views/admin/reports/UserReports"))
const TransactionReports = lazy(() => import("../../views/admin/reports/TransactionReports"))

// ** Merge Routes
const Routes = [
  {
    path: "/",
    index: true,
    element: <Navigate replace to={DefaultRoute} />
  },
  {
    path: "/home",
    element: <Home />
  },
  {
    path: "/home/filter",
    element: <HomePageFilter />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/desicionpage",
    element: <Desicionpage />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/search-amount",
    element: <Searchamount />
  },
  {
    path: "/search-result",
    element: <SearchResult />
  },
  {
    path: "/user-notification",
    element: <UserNotification />
  },
  {
    path: "/search-details",
    element: <SearchDetails />
  },
  {
    path: "/search-preview",
    element: <SearchPreview />
  },
  {
    path: "/confirmation",
    element: <Confirmation />
  },
  {
    path: "/success",
    element: <Success />
  },
  {
    path: "/rating",
    element: <UserRating />
  },
  {
    path: "/history",
    element: <TransactionHistory />
  },
  {
    path: "/accept",
    element: <TransactionAccept />
  },
  {
    path: "/chat",
    element: <Chat />
  },
  {
    path: "/support-chat/:id",
    element: <SupportChat />
  },
  {
    path: "/my_offers",
    element: <MyOfferesNew />
  },
  {
    path: "/Satellite_Request",
    element: <SatelliteRequest />
  },
  {
    path: "/propose_payment_methods",
    element: <ProposePaymentMethod />
  },
  {
    path: "/my-favourite-list",
    element: <UserFavoriteList />
  },
  {
    path: "/profile",
    element: <MyAccount />
  },
  {
    path: "/language",
    element: <Language />
  },
  {
    path: "/login",
    element: <LoginValidateEmail />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/loginValidateDob",
    element: <LoginValidateDob />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/loginValidateAuth",
    element: <LoginValidateAuth />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/loginValidatePassword",
    element: <LoginValidatePassword />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/loginValidateResponse",
    element: <LoginValidateResponse />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/register",
    element: <Register />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/error",
    element: <Error />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/logout",
    element: <Logout />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/my-support",
    index: true,
    element: <UserSuppot />
  },
  {
    path: "/not-authorized",
    element: <Notauthorized />,
    meta: {
      layout: "blank"
    }
  },
  // ** Admin Routes
  {
    path: "/admin",
    element: <Login />,
    meta: {
      layout: "blank"
    }
  },
  {
    path: "/admin/currency",
    element: <CurrencyTable />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/currency/add",
    element: <CurrencyAdd />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/currency/edit/:id",
    element: <CurrencyEdit />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/country",
    element: <CountryList />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/country/add",
    element: <CountryAdd />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/country/Edit/:id",
    element: <CountryEdit />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/payment_method/list",
    element: <PaymentMethodList />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/payment_method/add",
    element: <PaymentMethodAdd />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/payment_method/edit/:id",
    element: <PaymentMethodEdit />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/satellite_User_Request",
    element: <SatelliteUserList />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/dashboard",
    element: <Admindashboard />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/trade-history",
    element: <TradeHistory />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/support-list",
    element: <AdminSupportList />,  
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/user-management",
    element: <UserManagement />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/user-management/details/:id",
    element: <UserDetails />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/trade-details/:id",
    element: <TradeDetails />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/notification",
    element: <Notification />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/notification_history",
    element: <NotificationHistory />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/proposed_payments",
    element: <ProposedPayment />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/support-details/:id",
    element: <AdminSupportDetails />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/recent-transaction",
    element: <RecentTransaction />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/system-config",
    element: <SystemConfigation />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/report/satellite-user",
    element: <SatelliteReports />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/report/currency",
    element: <CureencyReports />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/report/payment-method",
    element: <PaymentMethodReports />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/report/user",
    element: <UserReports />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/report/transaction",
    element: <TransactionReports />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/pages",
    element: <AdminPageList />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "/admin/pages/edit/:id",
    element: <AdminpageEdit />,
    meta: {
      layout: "admin"
    }
  },
  {
    path: "admin/pages/add",
    element: <AdminPagesAdd />,
    meta: {
      layout: "admin"
    }
    
  }
]

const getRouteMeta = (route) => {
  if (isObjEmpty(route.element.props)) {
    if (route.meta) {
      return { routeMeta: route.meta }
    } else {
      return {}
    }
  }
}

// ** Return Filtered Array of Routes & Paths
const MergeLayoutRoutes = (layout, defaultLayout) => {
  const LayoutRoutes = []

  if (Routes) {
    Routes.filter((route) => {
      let isBlank = false
      // ** Checks if Route layout or Default layout matches current layout
      if (
        (route.meta && route.meta.layout && route.meta.layout === layout) ||
        ((route.meta === undefined || route.meta.layout === undefined) &&
          defaultLayout === layout)
      ) {
        let RouteTag = PublicRoute

        // ** Check for public or private route
        if (route.meta) {
          route.meta.layout === "blank" ? (isBlank = true) : (isBlank = false)
          RouteTag = route.meta.PrivateRoute ? PrivateRoute : PublicRoute

        }
        if (route.element) {
          const Wrapper =
            // eslint-disable-next-line multiline-ternary
            isObjEmpty(route.element.props) && isBlank === false
              ? // eslint-disable-next-line multiline-ternary
                LayoutWrapper
              : Fragment

          route.element = (
            <Wrapper {...(isBlank === false ? getRouteMeta(route) : {})}>
              <RouteTag route={route}>{route.element}</RouteTag>
            </Wrapper>
          )
        }

        // Push route to LayoutRoutes
        LayoutRoutes.push(route)
      }
      return LayoutRoutes
    })
  }
  return LayoutRoutes
}

const getRoutes = (layout) => {
  const defaultLayout = layout || "vertical"
  const layouts = ["vertical", "horizontal", "blank", "admin"]

  const AllRoutes = []

  layouts.forEach((layoutItem) => {
    const LayoutRoutes = MergeLayoutRoutes(layoutItem, defaultLayout)
    // console.log(LayoutRoutes)
    AllRoutes.push({
      path: "/",
      element: getLayout[layoutItem] || getLayout[defaultLayout],
      children: LayoutRoutes
    })
  })
  return AllRoutes
}

export { DefaultRoute, TemplateTitle, Routes, getRoutes }
