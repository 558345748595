// ** React Imports
import { Fragment } from "react"
import {useNavigate} from "react-router-dom"

// ** Custom Components
import NavbarUser from "./NavbarUser"
import Amountdropdown from "./Amountdropdown"
import p2p from "@src/assets/images/logo/p2plogo.svg"
// import neomoonLogo from "@src/assets/images/logo/neomoon.svg"
import backbutton from "@src/assets/images/pages/back.svg"
import { HIDE_BACK_BUTTON } from "./../../../../AppConfig"
import { sessionStorageUtils } from "../../../../utility/localStorage"
import {selectionUserTypes} from "../../../../AppConstants"

// ** Third Party Components
import { Sun, Moon, Menu } from "react-feather"

// ** Reactstrap Imports
import { NavItem, Row, Col} from "reactstrap"

const ThemeNavbar = (props) => {
  const navigate = useNavigate()
  
  // ** Props
  const { skin, setSkin, setMenuVisibility } = props
  const clickedUserType = sessionStorageUtils.getClickedUserType()
  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  const handleBackButton = () => {
    navigate(-1)
  }

  const handleLogo = () => {
    // Redirect to the Home page if the User clicked on CLIENT
        if (clickedUserType === selectionUserTypes.user) {
          navigate("/home/filter")
        } else {
          navigate("/history", {state:{satelliteHistory : 2}})
        }
  }
// To Hide back button
 const renderBackButtton = () => {
    const currentURL = window.location.href
    const pathname = window.location.pathname
    // console.log(pathname)

    const arrTemp  = HIDE_BACK_BUTTON.find((e) => {
        return e === pathname
    })
    const isHideBack  = (arrTemp && arrTemp.length > 0)
    if (isHideBack) {
      return <></>
    } else {
      return (
        <img src={backbutton} onClick={handleBackButton} alt="" height="35px" width="35px" className="d-flex justify-content-start"/>
      )
    }
 }


  return (
    <Fragment>
      
      <Row className="d-flex justify-content-center nk-header">
        <Col xs={12} md={12} xl={6} className="p-0">
        <div className="d-flex justify-content-between align-items-center nk-header">
          <div className="d-flex"> 
          <span className="me-1 backbutton feather">{renderBackButtton()}</span>
          {/* <img src={neomoonLogo} alt={""} onClick={handleLogo}/> */}
          <img src={p2p} alt={""} onClick={handleLogo} className="feather"/>
          </div>
          {/* <Amountdropdown/> */}
          <NavbarUser skin={skin} setSkin={setSkin} setMenuVisibility={setMenuVisibility} />
        </div>
      </Col>
      </Row>
      </Fragment>
  )
}

export default ThemeNavbar
