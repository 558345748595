import { createSlice } from '@reduxjs/toolkit'

const initalData = () => {
    return {
        startDate : '',
        endDate : '',
        buttonType : '1'
    }
}

export const historyFilterSlice = createSlice({
    name: 'historyFilter',
    initialState: {
        setFilterData: initalData()
        
    },
    reducers: {
        FilterData: (state, action) => {
            // console.log(action)
            state.setFilterData.startDate = action.payload.startDate
            state.setFilterData.endDate = action.payload.endDate
            state.setFilterData.buttonType = action.payload.acceptValue
            

        }
    }
})
export const { FilterData } = historyFilterSlice.actions
  
  export default historyFilterSlice.reducer
  