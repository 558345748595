import { createSlice } from '@reduxjs/toolkit'
import { boolean } from 'yup'

const initalData = () => {
    return {
        showToast : '',
        toastMessage : '',
        toastType : ''
    }
}

export const selectionSlice = createSlice({
    name: 'toast',
    initialState: {
        setToast: initalData()
        
    },
    reducers: {
        showToast: (state, action) => {
            
            state.setToast.showToast = action.payload.showToaster
            state.setToast.toastMessage = action.payload.message
            state.setToast.toastType = action.payload.toastType
        }
    }
  })
  export const { showToast } = selectionSlice.actions
  
  export default selectionSlice.reducer
  