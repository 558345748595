import { Mail, Home, Star, Settings, User, Bell, Power, Zap, RotateCcw, Heart, Headphones, Type, DollarSign } from "react-feather"

export default [
  {
    id: "home",
    title: "Home",
    icon: <Home size={20} />,
    navLink: "/home"
  },
  {
    id: "history",
    title: "Histórico de Transacciones",
    icon: <RotateCcw size={20} />,
    navLink: "/history"
  },
  {
    id: "My Account",
    title: "Mi Cuenta",
    icon: <User size={20} />,
    navLink: "/profile"
  },
  {
    id: "My_Offers",
    title: "Mis Ofertas",
    icon: <Zap size={20} />,
    navLink: "/my_offers"
  },
  {
    id: "Satellite_Request",
    title: "Solicitud de Satelite",
    icon: <Zap size={20} />,
    navLink: "/Satellite_Request"
  },
  {
    id: "Propose_Payment_Method",
    title: "Propose Payment Method",
    icon: <DollarSign size={20} />,
    navLink: "/propose_payment_methods"
  },
  {
    id: "Notification",
    title: "Notificaciones",
    icon: <Bell size={20} />,
    navLink: "/user-notification"
  },
  {
    id: "UserFavoriteList",
    title: "Usuarios Favoritos",
    icon: <Heart size={20} />,
    navLink: "/my-favourite-list"
  },
  {
    id: "support",
    title: "Soporte",
    icon: <Headphones size={20} />,
    navLink: "/my-support"
  },
  {
    id: "Idioma",
    title: "Idioma",
    icon: <Type size={20}/>,
    navLink: "/language"
  },
  {
    id: "Logout",
    title: "Cerrar Sesión",
    icon: <Power size={20} />,
    navLink: "*"
  }
]