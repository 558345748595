// ** React Imports
import { Outlet, useNavigate, useLocation } from "react-router-dom"

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/AdminLayout"

// ** Menu Items Array
import navigation from "@src/navigation/adminmenu"
import { isUserLoggedIn } from "@utils"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ComponentSpinner from "../@core/components/spinner/Loading-spinner"
import { localStorageUtils, sessionStorageUtils } from '../utility/localStorage'
import toast from "react-hot-toast"
import { showToast } from "../redux/toast"
import { userType } from "../utility/Utils"

const VerticalLayout = (props) => {
  // console.log("Admin Layout")
  const userData = useSelector((state) => state.auth.userData)
  const showToaster = useSelector((state) => state.toast.setToast)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()

  if (showToaster.showToast === true && showToaster.toastType === "success") {
    toast.success(showToaster.toastMessage)
    setTimeout(() => {
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 0)

  } else if (showToaster.showToast === true && showToaster.toastType === 'error') {
    toast.error(showToaster.toastMessage)
    setTimeout(() => {
      const reset = {
        showToaster: false
      }
      dispatch(showToast(reset))
    }, 1000)
  } else {
  }


  const loginRedirection = () => {
    if (isUserLoggedIn()) {
      //User Logged in so we will allow user to go ahead and play with apps
      const loginUsertype = userType.Administrator

      const currentType = localStorageUtils.getUserRole()
      const token = localStorageUtils.getAccessToken()

      if (!token) {
        toast.error("Unauthorized Access!")
        navigate("/admin")
      }
      if (Number(currentType) !== loginUsertype) {
        toast.error("Unauthorized Access!")
        navigate("/not-authorized")
      }
    } else {
      navigate("/admin")
    }
  }

  useEffect(() => {
    loginRedirection()
    // checkValidUser()
  }, [userData])

  return (
    <Layout menuData={navigation} {...props}>
      {/* {(loaderState && loaderState === true) ? <ComponentSpinner /> : "" } */}
      <Outlet />
    </Layout>


  )
}

export default VerticalLayout
