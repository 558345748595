// ** Vertical Menu Components
import AdminNavMenuLink from "./AdminNavMenuLink"
import AdminNavMenuGroup from "./AdminNavMenuGroup"
import AdminNavMenuSectionHeader from "./AdminNavMenuSectionHeader"

// ** Utils
import { resolveAdminNavMenuItemComponent as resolveNavItemComponent,
  canViewMenuGroup,
  canViewMenuItem
 } from "@layouts/utils"

const AdminMenuNavItems = (props) => {
  // ** Components Object
  const Components = {
    AdminNavMenuLink,
    AdminNavMenuGroup,
    AdminNavMenuSectionHeader
  }

  // ** Render Nav Menu Items
  const RenderNavItems = props.items.map((item, index) => {
    const TagName = Components[resolveNavItemComponent(item)]
    if (item.children) {
      return (
        canViewMenuGroup(item) && (
          <TagName item={item} index={index} key={item.id} {...props} />
        )
      )
    }
    return <TagName key={item.id || item.header} item={item} {...props} />
  })

  return RenderNavItems
}

export default AdminMenuNavItems
