import Axios from "axios"

import { localStorageUtils } from "../utility/localStorage"
import { API_ENDPOINT, PUBLIC_AUTHORIZATION_TOKEN } from "../AppConfig"
import { appConstant } from "../AppConstants"
import { store } from "../redux/store"
import { handleLogout } from "../redux/authentication"
import ComponentSpinner from "../@core/components/spinner/Loading-spinner"
import { hideLoader, showLoader } from "../redux/loader"
import { Link } from "react-router-dom"

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0"

export function isValidResponse(resp) {
  return resp && resp.status === 200 && resp.data.code === 200
}

const baseURL = API_ENDPOINT
const apiPrefix = "/api/"

export const CONFIG = Axios.create({
  baseURL: `${baseURL}${apiPrefix}`
})

CONFIG.interceptors.request.use((config) => {
  let token = localStorageUtils.getAccessToken()
  
  if (token) {
    token = localStorageUtils.getAccessToken()
  } else {
    token = PUBLIC_AUTHORIZATION_TOKEN
  }

  // config.headers.Allow = `"Access-Control-Allow-Origin" : "*"`
  config.headers.Authorization = `${token}`
  config.headers["Content-Type"] = "application/json"
  return config
})

export const httpMethods = {
  GET: "GET",
  POST: "POST",
  PUT: "PUT",
  DELETE: "DELETE",
  GET_IMAGE: "GET_IMAGE"
}

export const serviceMaker = async (url, method, data = {}) => {
  try {
    // console.log(data)
    let result
    const APIInstance = CONFIG

    switch (method) {
      case httpMethods.GET: {
        store.dispatch(showLoader())
        result = await APIInstance.get(url)
        break
      }
      case httpMethods.POST: {
        store.dispatch(showLoader())
        result = await APIInstance.post(url, data)
        break
      }
      case httpMethods.PUT: {
        result = await APIInstance.put(url, data)
        break
      }
      case httpMethods.DELETE: {
        result = await APIInstance.delete(url, data)
        break
      }
      case httpMethods.GET_IMAGE: {
        result = await APIInstance.get(`${baseURL}${url}`, {
          responseType: "arraybuffer"
        })
        break
      }
      default: {
        throw appConstant.INVALID_METHOD
      }
    }
    // console.log(result)
    if (!isValidResponse(result)) {
      throw appConstant.INVALID_RESPONSE
    }
    store.dispatch(hideLoader())

    return result.data 
    
  } catch (err) {
    store.dispatch(hideLoader())
    const errorData = err.response
    //console.log(err.response)
   // console.log(err.response.code)
    if (errorData.status === 401 || errorData.status === "401") {
      //"Not Authorized"
      store.dispatch(handleLogout())
      return errorData.data
    }  else if (errorData.status === 412 || errorData.status === "412") {
      return errorData.data
    } 

    // throw new APIError(
    //   err.response
    //     ? err.response
    //     : {
    //         data: {
    //           status: "error",
    //           code: 500,
    //           message: "Something went wrong",
    //           data: null,
    //         }
    //       }
    // )
  }
}


export const addImageMaker = async (url, method, data = {}) => {
  try {
    // console.log(data)
    let result
    const APIInstance = CONFIG

    APIInstance.interceptors.request.use((config) => {
      const token = localStorageUtils.getAccessToken()
      // config.headers.Origin = `"Access-Control-Allow-Origin" : "*"`
      config.headers.Authorization = `${token}`

      config.headers["Content-Type"] = "multipart/form-data"
      return config
    })

    switch (method) {
      
      case httpMethods.POST: {
        store.dispatch(showLoader())
        result = await APIInstance.post(url, data)
        break
      }
      
      default: {
        throw appConstant.INVALID_METHOD
      }
    }
    // console.log(result)
    if (!isValidResponse(result)) {
      throw appConstant.INVALID_RESPONSE
    }
    store.dispatch(hideLoader())

    return result.data 
    
  } catch (err) {
    store.dispatch(hideLoader())
    const errorData = err.response
    if (errorData.status === 401 || errorData.status === "401") {
      //"Not Authorized"
      store.dispatch(handleLogout())
    } else if (errorData.status === 412 || errorData.status === "412") {
      return errorData.data
    }
  }
}

// class APIError {
//   data
//   constructor(msg) {
//     this.data = msg.data;
//   }
// }
