// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
import { localStorageUtils } from '../utility/localStorage'
//import useJwt from '@src/auth/jwt/useJwt'

const initalData = () => {
  window.localStorage.getItem('user')
  const finalAmount = window.sessionStorage.getItem('finalAmount')
  //** Parse stored json or if none return initialValue
  return {
    selectionUserTypes : JSON.parse(window.sessionStorage.getItem('Select-usertype')),
    selectionOption : JSON.parse(window.sessionStorage.getItem('Select-option')),
    fromCurrency : JSON.parse(window.sessionStorage.getItem('fromCurrency')),
    toCurrency : JSON.parse(window.sessionStorage.getItem('toCurrency')),
    displayFromCurrency : JSON.parse(window.sessionStorage.getItem('displayFromCurrency')),
    displayToCurrency : JSON.parse(window.sessionStorage.getItem('displayToCurrency')),
    paymentMethod : JSON.parse(window.sessionStorage.getItem('paymentMethod')),
    fromAmount : JSON.parse(window.sessionStorage.getItem('fromAmount')),
    toAmount : JSON.parse(window.sessionStorage.getItem('toAmount')),
    amount : JSON.parse(window.sessionStorage.getItem('amount')),
    finalAmount : JSON.parse(finalAmount !== undefined && finalAmount !== null ? finalAmount : 0)
  }
}
export const selectionSlice = createSlice({
  name: 'selection',
  initialState: {
    selectionData: initalData()
  },
  reducers: {
    handleSelection: (state, action) => {
      // console.log(state)
      // console.log(action.payload)
      window.sessionStorage.setItem('Select-option', JSON.stringify(action.payload))
      state.selectionData.selectionOption = action.payload
    },
    handleSelectionUserType: (state, action) => {
      // console.log(state)
      // console.log(action.payload)
      window.sessionStorage.setItem('Select-usertype', JSON.stringify(action.payload))
      state.selectionData.selectionUserTypes = action.payload
    },
    handleSearchData: (state, action) => {
      // console.log(state)
      // console.log(action.payload)
      window.sessionStorage.setItem('fromCurrency', JSON.stringify(action.payload.fromCurrency))
      window.sessionStorage.setItem('toCurrency', JSON.stringify(action.payload.toCurrency))
      window.sessionStorage.setItem('displayFromCurrency', JSON.stringify(action.payload.displayFromCurrency))
      window.sessionStorage.setItem('displayToCurrency', JSON.stringify(action.payload.displayToCurrency))
      window.sessionStorage.setItem('paymentMethod', JSON.stringify(action.payload.paymentMethod))
      window.sessionStorage.setItem('toAmount', JSON.stringify(action.payload.toAmount))
      window.sessionStorage.setItem('fromAmount', JSON.stringify(action.payload.fromAmount))
      window.sessionStorage.setItem('amount', JSON.stringify(action.payload.amount))
      window.sessionStorage.setItem('finalAmount', JSON.stringify(action.payload.finalAmount))
      state.selectionData.fromCurrency = action.payload.fromCurrency
      state.selectionData.toCurrency = action.payload.toCurrency
      state.selectionData.displayFromCurrency = action.payload.displayFromCurrency
      state.selectionData.displayToCurrency = action.payload.displayToCurrency
      state.selectionData.paymentMethod = action.payload.paymentMethod
      state.selectionData.toAmount = action.payload.toAmount
      state.selectionData.fromAmount = action.payload.fromAmount
      state.selectionData.amount = action.payload.amount
      state.selectionData.finalAmount = action.payload.finalAmount
    },
    clearSelection: state => {
      state.selectionData = initalData()

    }
  }
})

export const { handleSelection, clearSelection, handleSearchData, handleSelectionUserType } = selectionSlice.actions

export default selectionSlice.reducer
