// ** Icons Import
import { Heart } from "react-feather"

const Footer = () => {
  return (
    <p className="clearfix mb-0 d-flex justify-content-center">
      <span className="float-md-start d-block d-md-inline-block mt-25">
        Powered By &nbsp;
        {/* © {new Date().getFullYear()}{" "} */}
        <a
          href="https://neomoon.app/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <strong>Neomoon</strong>
        </a>
        <span className="d-none d-sm-inline-block">, All rights Reserved</span>
      </span>
    </p>
  )
}

export default Footer
