// ** React Imports
import { Fragment, useState, useRef, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"

// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"

// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader"
import VerticalNavMenuItems from "./VerticalNavMenuItems"
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from "reactstrap"
import neommonLogo from "@src/assets/images/logo/neomoon.svg"
import { handleLogout } from "../../../../../redux/authentication"
import { useNavigate } from "react-router-dom"
import {handleMenuIdData} from "../../../../../redux/menuIdSelection"
import { useTranslation } from "react-i18next"
const Sidebar = (props) => {
  // ** Props
  const { menuCollapsed, menu, skin, menuData } = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)
  const [centeredModal, setCenteredModal] = useState(false) 
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const optionSelection = (menuId) => {
    dispatch(handleMenuIdData(menuId))
  }
  const {t} = useTranslation()
  // ** Id For Logout 
   const menuId = useSelector((state) => state.menuIdSelectionData.menuId)
  
  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }
  const Logout = () => {
    dispatch(handleLogout())
    navigate("/")
    optionSelection(0)

}

const handleCancle = () => {
    setCenteredModal(!centeredModal)
    optionSelection(0)
}
const logoutPage = () => {

return  <div className="vertically-centered-modal">
  <Modal
    isOpen={centeredModal}
    toggle={() => setCenteredModal(!centeredModal)}
    className="modal-dialog-centered blue-modal modal-xs all-sweet-alt-model-font-size">
    <ModalHeader toggle={() => setCenteredModal(!centeredModal)}>
    </ModalHeader>
    <ModalBody>
      <div className="mb-1">
        <img className='mx-auto' src={neommonLogo} alt="" />
      </div>
      {t('logout_model_mes')}
    </ModalBody>
    <ModalFooter>
    <Button color="dark" 
        onClick={() => handleCancle()}>
            {t('logout_model_cancel_btn')}
      </Button>{" "}
      <Button
        color="primary"
        onClick={() => Logout()}>
         {t('ok_model_btn')}
      </Button>{" "}
    </ModalFooter>
  </Modal>
</div>
}
  useEffect(() => {
   
    if (menuId === "Logout") {  
      setCenteredModal(!centeredModal)
      optionSelection(0)
    }
    }, [menuId])

  // ** Scroll Menu
  const scrollMenu = (container) => {
    if (shadowRef && container.scrollTop > 0) {
      if (!shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.add("d-block")
      }
    } else {
      if (shadowRef.current.classList.contains("d-block")) {
        shadowRef.current.classList.remove("d-block")
      }
    }
  }

    // console.log(props.menuData.map((item) => console.log(item.id))) 
     
    // if () {

    // }

  return (
    <Fragment>
      <div
        className={classnames(
          "main-menu menu-fixed menu-accordion menu-shadow",
          {
            expanded: menuHover || menuCollapsed === false,
            "menu-light": skin !== "semi-dark" && skin !== "dark",
            "menu-dark": skin === "semi-dark" || skin === "dark"
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu({ ...props })
        ) : (
          
          <Fragment>
            {logoutPage()}

            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Header Shadow */}
            <div className="" ref={shadowRef}></div>
            {/* Perfect Scrollbar */}
            <PerfectScrollbar
              className="main-menu-content"
              options={{ wheelPropagation: false }}
              onScrollY={(container) => scrollMenu(container)}
            >
              <ul className="navigation navigation-main">
                <VerticalNavMenuItems
                  items={menuData}
                  menuData={menuData}
                  menuHover={menuHover}
                  groupOpen={groupOpen}
                  activeItem={activeItem}
                  groupActive={groupActive}
                  setGroupOpen={setGroupOpen}
                  menuCollapsed={menuCollapsed}
                  setActiveItem={setActiveItem}
                  setGroupActive={setGroupActive}
                  currentActiveGroup={currentActiveGroup}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                />
              </ul>
            </PerfectScrollbar>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
