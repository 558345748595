
import {
  APP_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT,
  PER_PAGE_ROWS
 } from "./AppConfig"

export const appConstant = {
  GRID_PAGE_SIZE: PER_PAGE_ROWS,
  APP_DATE_FORMAT,
  DATEPICKER_DATE_FORMAT,
  INVALID_METHOD: "Invalid Method",
  INVALID_RESPONSE: "Invalid Response"
}

export const httpResponseCode = {
  RES_CODE_200: 200,
  RES_CODE_500: 500
  
}

export const appErrors = {
  UNKNOWN_ERROR_TRY_AGAIN: 'Unknown error occured. Please try again.'
 }

export const apiEndPoints = {
  NOTIFICATION_TOKEN_REG: "user/token/create",
  //* user side
  LOGIN: "admin/user/login",
  GET_DROPDOWN_DATA: "/user/getDropDownData",
  GET_DROPDOWN_DATA_NEW: "user/getDropDownData/new",
  GET_PAYMENTMETHOD_DATA: "user/getUserPaymentMethodData?userId=",
  FAVORITE: "user/favorite",
  TRANSACTION_DETAILS: "transaction/details",
  CONFIRM_DETAILS: "transaction/confirm",
  ACCEPT_TRANSACTION_DETAILS: "transaction/accept",
  GIVE_RATTING: "user/ratting",
  TRANSCATION_HISTORY:"transaction/history",
  SEARCH_USER: "user/sell_buy_filter",
  GET_SEARCH_USER: "user/sell_buy_filter/new",
  CREATE_TRANSACTION: "transaction/create",
  LOGOUT: "logout",
  RESEND_OTP: "resend-otp",
  CHAT_LIST: "chat/list",
  GET_SUPPORT_CHAT_LIST:"user/support/details",
  USER_NOTIFICATION:"user/notification/list",
  USER_UPDATE_NOTIFICATION: "user/notification/update",
  CREATE_CHAT: "chat/create",
  USER_VALIDATION: "user/validation",
  USER_GOOGLE_AUTH: "user/google",
  USER_LOGIN: "user/login_neomoon",
  SEARCH_USER_AUTH: "user/search",
  REPLY_USER_AUTH: "user/reply",
  CHAT_WITH_IMAGE:"chat/create/image",
  SUPPORT_CHAT_WITH_IMAGE: "user/support/action",
  SUPPORT_CLOSR_TICKET_ACTION: "/user/support/status",
  SEND_SATELLITE_REQUEST:"user/satellite/request",
  SHOW_SATELLITE_STATUS: "user/satellite/get",
  PROPOSE_PAYMENT_METHOD: "user/satellite/request/paymentmethod",
  UPDATE_SATELLITE_REQUEST: "user/satellite/create",
  USER_FAVORITE_LIST: "/user/favorite/list",
  USER_SUPPORT_LIST: "user/support/list",
  USER_SUPPORT_TICKET: "user/support/create",
  USER_AMOUNT_LIST: "user/wallet",
  USER_STATIC_PAGE_ADD: "user/staticpage/slug",
  DOCUMENT_VERIFIED: "user/document/upload/status",

  // ** ADMIN API'S
  USER_LIST: "admin/user/list",
  USER_DETAILS: "admin/user/detail",
  USER_TRANSACTION_LIST: "admin/user/transaction/list",
  USER_LIST_ACTION: "admin/user/action",
  SET_ADMIN_FAVORITE: "admin/preferred/status",
  INVITE_SATELLITE: "admin/user/invite",
  DOCUMNER_VERIED_REQ: "admin/satellite/document/verified/req",

  
  CURRENCYLIST: "admin/currency/list",
  CURRENCY_ADD: "admin/currency/add",
  CURRENCY_EDIT: "admin/currency/edit",
  CURRENCY_DELETE: "admin/currency/delete",
  CURRENCY_ACTION: "admin/currency/action",
  
  COUNTRY_LIST: "admin/country/list",
  COUNTRY_ADD: "admin/country/add",
  COUNTRY_EDIT: "admin/country/edit",
  COUNTRY_DELETE: "admin/country/delete",
  COUNTRY_ACTION: "admin/country/action",

  PAYMENT_METHOD_LIST: "admin/payment/list",
  PAYMENT_METHOD_ACTION: "admin/payment/status",
  PAYMENT_METHOD_ADD: "admin/payment/add",
  PAYMENT_METHOD_EDIT: "admin/payment/edit",
  PAYMENT_METHOD_DELETE: "admin/payment/delete",
  PROPOSED_PAYMENTS_LIST: "admin/satellite/payment/list",
  PROPOSED_PAYMENT_STATUS: "/admin/satellite/payment/status",

  ADMIN_STATICPAGE_LIST: "admin/staticpages/list",
  ADMIN_STATICPAGE_ADD: "admin/staticpages/add",
  ADMIN_STATICPAGE_EDIT: "admin/staticpages/edit",
  ADMIN_STATICPAGE_DELETE: "admin/staticpages/delete",
  ADMIN_STATICPAGE_ACTION: "admin/staticpages/action",


  SATELLITE_LIST: "admin/satellite_request/list",
  SATELLITE_STATUS: "admin/satellite_request/status",

  IMAGE_UPLOAD: "admin/image/add",
  IMAGE_EDIT: "admin/image/edit",

  TRANSACTION_LIST: "admin/transaction/list",
  GET_ADMIN_TRANSACTION_DETAILS: "admin/transaction/detail",
  GET_ADMIN_CHAT_DETAILS: "admin/chatting/detail",

  DASHBOARD_DATA: "admin/dashboard",
  SUPPORTDATA_LIST: "admin/support/list",
  
TRANSACTION_CANCEL: "admin/transaction/cancel",
SUPPORTDATA_LIST: "admin/support/list",
  SUPPORTDATA_DETAILS: "admin/support/details",
  SUPPORTDATA_WITH_IMAGE: "admin/support/action",
  SUPPORTDATA_RESOLVE_STATUS: "admin/support/status",

  ADMIN_NOTIFICATION_List:"admin/notification/list",
  ADMIN_UPDATE_NOTIFIAVTION: "admin/notification/update",

  SYSTEM_CONFIG_CANCEL_TIME_GET: "/admin/config/cancellation-time",
  SYSTEM_CONFIG_CANCEL_TIME_UPDATE: "/admin/config/cancellation-time/update",

  MOST_ACTIVE_SATELLITE_REPORT: "/admin/report/satellite",
  MOST_ACTIVE_USERS_REPORT: "/admin/report/user",
  MOST_ACTIVE_PAYMENT_METHOD_REPORT: "/admin/report/paymentMethod",
  MOST_CHAT_TRASACTION_REPORT: "/admin/report/transaction",
  MOST_ACTIVE_CURRENCY_REPORT: "/admin/report/currency"
}

export const adminRoutePath = {
  dashboard: "/admin/board"
}

export const adminRoutePathList = Object.entries(adminRoutePath).map(
  ([key, value]) => {
    return value
  }
)

export const publicRoutePath = {
  appEntryPoint: "/",
  vendorRegister: "/accounts/register",
  login: "/accounts/login",
  accountVerification: "/accounts/verify-otp",
  pageNotFound: "/page-not-found"
}

export const selectionOption = {
  buy : "1",
  sell : "2"
}

export const selectionUserTypes = {
  satellite : "2",
  user : "3"
}

export const userRouteAuth = ["/my_offers"]

export const sateliteRouteAuth = ["/my-favourite-list", "/home"]