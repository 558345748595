import { Mail, Home, Star, Clock, User, Bell, MessageSquare, Zap, RotateCcw, Settings, DollarSign, Slack, Globe, Headphones, Layout, File} from "react-feather"

export default [
  {
  
    id: "",
    title: "Dashboard",
    icon: <Home size={20} />,
    navLink: "admin/dashboard"
  },
  {
    id: "",
    title: "User Management",
    icon: <User size={20} />,
    navLink: "/admin/user-management"
   
    // navLink: "/profile"
  },
  {
    id: "history",
    title: "Transaction History",
    icon: <RotateCcw size={20} />,
    navLink: "/admin/trade-history"
  },
  {
    id: "",
    title: "Satellite Request",
    icon: <Zap  size={20}/>,
    navLink: "/admin/satellite_User_Request"
  },
  {
    id: "",
    title: "Add Notifications",
    icon: <MessageSquare size={20} />,
    navLink: "/admin/notification"
  },
  {
    id: "",
    title: "Notifications History",
    icon: <Bell size={20} />,
    navLink: "/admin/notification_history"
  },
  {
    id: "",
    title: "Proposed Payments",
    icon: <DollarSign size={20} />,
    navLink: "/admin/proposed_payments"
  },
  {
    id: "Support",
    title: "Support",
    icon: <Headphones size={20} />,
    navLink: "/admin/support-list"
  },

  {
    id: "Systemconfig",
    title: "System config",
    icon: <Clock size={20} />,
    navLink: "/admin/system-config"
  },
  {
    id: "report",
    title: "Reports",
    icon: <File size={20} />,
    children: [
      {
        id: '',
        title: 'Active Satellite Users',
        icon: <Zap />,
        navLink: '/admin/report/satellite-user'
      },
      {
        id: '',
        title: 'Most Active Currency',
        icon: <DollarSign/>,
        navLink: '/admin/report/currency'
      },
      {
        id: '',
        title: 'Most Active Payment Method',
        icon: <Slack />,
        navLink: '/admin/report/payment-method'
      },
      {
        id: '',
        title: 'Most Active Users',
        icon: <User />,
        navLink: '/admin/report/user'
      },
      {
        id: '',
        title: 'Transaction By Satellite',
        icon: <RotateCcw />,
        navLink: '/admin/report/transaction'
      }
    ]
  },
  {
    id: "pages",
    title: "Pages",
    icon: <Layout size={20} />,
    navLink: "/admin/pages"
  },
  {
    id: "",
    title: "Neomoon Masters",
    icon: <Settings size={20} />,
    children: [
      {
        id: '',
        title: 'Country Mater',
        icon: <Globe />,
        navLink: '/admin/country'
      },
      {
        id: '',
        title: 'Currency Master',
        icon: <DollarSign/>,
        navLink: '/admin/currency'
      },
      {
        id: '',
        title: 'Payment Master',
        icon: <Slack />,
        navLink: '/admin/payment_method/list'
      }
    ]
  }
  // {
  //   id: "",
  //   title: "LogOut",
  //   icon: <Power size={20} />,
  //   navLink: "/logout"
  // }
  
  //,
  // {
  //   id: "searchamount",
  //   title: "Second Page",
  //   icon: <Mail size={20} />,
  //   navLink: "/search-amount"
  // },
  // {
  //   id: "searchresult",
  //   title: "Search Result",
  //   icon: <Mail size={20} />,
  //   navLink: "/search-result"
  // },
  // {
  //   id: "searchdetails",
  //   title: "Search Details",
  //   icon: <Mail size={20} />,
  //   navLink: "/search-details"
  // },
  // {
  //   id: "searchpreview",
  //   title: "Search Previews",
  //   icon: <Mail size={20} />,
  //   navLink: "/search-preview"
  // },
  // {
  //   id: "confirmation",
  //   title: "Confirmation",
  //   icon: <Mail size={20} />,
  //   navLink: "/confirmation"
  // },
  // {
  //   id: "success",
  //   title: "Success",
  //   icon: <Mail size={20} />,
  //   navLink: "/success"
  // },
  // {
  //   id: "rating",
  //   title: "Rating",
  //   icon: <Mail size={20} />,
  //   navLink: "/rating"
  // },
  // {
  //   id: "history",
  //   title: "History",
  //   icon: <Mail size={20} />,
  //   navLink: "/history"
  // }
]
