

export const API_ENDPOINT =
   process.env.REACT_APP_API_ENDPOINT || "https://apip2p.neomoon.app"
  // process.env.REACT_APP_API_ENDPOINT || "http://35.225.75.163/stag"
  // process.env.REACT_APP_API_ENDPOINT || "http://34.69.89.56"
  //  process.env.REACT_APP_API_ENDPOINT || "http://localhost:3000"

export const APP_DATE_FORMAT = process.env.APP_DATE_FORMAT || "DD-MM-YYYY"
export const DATEPICKER_DATE_FORMAT =
  process.env.DATEPICKER_DATE_FORMAT || "YYYY-MM-DD"

export const FIREBASE_KEY = process.env.FIREBASE_KEY || "BGfhTtRKnjax1HORctbIhrNBpPS_N1bBl9RpKQr4VnRU0F7jvmP4pWxfxcVsravNqmBxoDLUezncjgs9Tnk3wpI"

export const FIREBASE_CONFIG = {
  apiKey: "AIzaSyDg__9AIFUgoqAPG6UhsuFy5Y0DHcA6FUw",
    authDomain: "neomoon-92487.firebaseapp.com",
    projectId: "neomoon-92487",
    storageBucket: "neomoon-92487.appspot.com",
    messagingSenderId: "1008165204138",
    appId: "1:1008165204138:web:5b6335771d352c775b60d3"
}
export const PER_PAGE_ROWS_OPTIONS = [10, 15, 25, 50, 100]
export const PER_PAGE_ROWS = process.env.PER_PAGE_ROWS || 10
export const PUBLIC_AUTHORIZATION_TOKEN = process.env.NEXT_PUBLIC_AUTHORIZATION_TOKEN || 'PUBLIC_AUTHORIZATION_TOKEN'

export const HIDE_BACK_BUTTON = ["/desicionpage", "/success", "/rating", "/history", "/home"]