import { apiEndPoints } from "../AppConstants"
import { addImageMaker, httpMethods, serviceMaker } from "./ServiceWarpper"


export const FETCH_ALL_USERS = (payload) => serviceMaker(
    `${apiEndPoints.FETCH_ALL_USERS}?searchFilter=${payload.searchFilter}
&perPageRows=${payload.perPageRows}&currentPage=${payload.currentPage}
&orderBy=${payload.orderBy}&sortBy=${payload.sortBy}`,
    httpMethods.GET
  )

export const SEARCH_USERS = (payload) => serviceMaker(`${apiEndPoints.SEARCH_USER}`, httpMethods.POST, payload)

export const USER_SUPPORT_TICKET = (payload) => serviceMaker(`${apiEndPoints.USER_SUPPORT_TICKET}`, httpMethods.POST, payload)

export const CREATE_TRANSCATION = (payload) => serviceMaker(`${apiEndPoints.CREATE_TRANSACTION}`, httpMethods.POST, payload)

export const GET_TRANSACTION_DETAILS = (payload) => serviceMaker(`${apiEndPoints.TRANSACTION_DETAILS}`, httpMethods.POST, payload)

export const CONFIRM_TRANSACTION_DETAILS = (payload) => serviceMaker(`${apiEndPoints.CONFIRM_DETAILS}`, httpMethods.POST, payload)

export const ACCEPT_TRANSACTION_DETAILS = (payload) => serviceMaker(`${apiEndPoints.ACCEPT_TRANSACTION_DETAILS}`, httpMethods.POST, payload)

export const GET_SUPPORT_CHAT_LIST = (payload) => serviceMaker(`${apiEndPoints.GET_SUPPORT_CHAT_LIST}`, httpMethods.POST, payload)

export const GET_CHAT_LISTING = (payload) => serviceMaker(`${apiEndPoints.CHAT_LIST}`, httpMethods.POST, payload)

export const USER_NOTIFICATION = (payload) => serviceMaker(`${apiEndPoints.USER_NOTIFICATION}`, httpMethods.POST, payload)

export const USER_UPDATE_NOTIFICATION = (payload) => serviceMaker(`${apiEndPoints.USER_UPDATE_NOTIFICATION}`, httpMethods.POST, payload)

export const USER_SUPPORT_LIST = (payload) => serviceMaker(`${apiEndPoints.USER_SUPPORT_LIST}`, httpMethods.POST, payload)

export const CREATE_CHAT = (payload) => serviceMaker(`${apiEndPoints.CREATE_CHAT}`, httpMethods.POST, payload)

export const SUPPORT_CHAT_WITH_IMAGE = (payload) => addImageMaker(`${apiEndPoints.SUPPORT_CHAT_WITH_IMAGE}`, httpMethods.POST, payload)

export const SUPPORT_CLOSR_TICKET_ACTION = (payload) => serviceMaker(`${apiEndPoints.SUPPORT_CLOSR_TICKET_ACTION}`, httpMethods.POST, payload)

export const CHAT_WITH_IMAGE = (payload) => addImageMaker(`${apiEndPoints.CHAT_WITH_IMAGE}`, httpMethods.POST, payload)

export const GET_ADMIN_TRANSACTION_DETAILS = (payload) => serviceMaker(`${apiEndPoints.GET_ADMIN_TRANSACTION_DETAILS}`, httpMethods.POST, payload)

export const GET_ADMIN_CHAT_DETAILS = (payload) => serviceMaker(`${apiEndPoints.GET_ADMIN_CHAT_DETAILS}`, httpMethods.POST, payload)

export const USER_STATIC_PAGE_ADD = (payload) => serviceMaker(`${apiEndPoints.USER_STATIC_PAGE_ADD}`, httpMethods.POST, payload)

export const TRANSACTION_LIST = (payload) => serviceMaker(`${apiEndPoints.TRANSACTION_LIST}`, httpMethods.POST, payload)
export const DASHBOARD_DATA = (payload) => serviceMaker(`${apiEndPoints.DASHBOARD_DATA}`, httpMethods.POST, payload)

export const USER_LIST = (payload) => serviceMaker(`${apiEndPoints.USER_LIST}`, httpMethods.POST, payload)
export const INVITE_SATELLITE = (payload) => serviceMaker(`${apiEndPoints.INVITE_SATELLITE}`, httpMethods.POST, payload)
export const DOCUMNER_VERIED_REQ = (payload) => serviceMaker(`${apiEndPoints.DOCUMNER_VERIED_REQ}`, httpMethods.POST, payload)
export const USER_DETAILS = (payload) => serviceMaker(`${apiEndPoints.USER_DETAILS}`, httpMethods.POST, payload)
export const USER_TRANSACTION_LIST = (payload) => serviceMaker(`${apiEndPoints.USER_TRANSACTION_LIST}`, httpMethods.POST, payload)
export const USER_LIST_ACTION = (payload) => serviceMaker(`${apiEndPoints.USER_LIST_ACTION}`, httpMethods.POST, payload)
export const SET_ADMIN_FAVORITE = (payload) => serviceMaker(`${apiEndPoints.SET_ADMIN_FAVORITE}`, httpMethods.POST, payload)
export const USER_AMOUNT_LIST = (payload) => serviceMaker(`${apiEndPoints.USER_AMOUNT_LIST}`, httpMethods.POST, payload)
 
export const CURRENCYLIST_DATA = (payload) => serviceMaker(`${apiEndPoints.CURRENCYLIST}`, httpMethods.POST, payload)
export const CURRENCY_ADD = (payload) => serviceMaker(`${apiEndPoints.CURRENCY_ADD}`, httpMethods.POST, payload)
export const CURRENCY_EDIT = (payload) => serviceMaker(`${apiEndPoints.CURRENCY_EDIT}`, httpMethods.POST, payload)
export const CURRENCY_DELETE = (payload) => serviceMaker(`${apiEndPoints.CURRENCY_DELETE}`, httpMethods.POST, payload)
export const CURRENCY_ACTION = (payload) => serviceMaker(`${apiEndPoints.CURRENCY_ACTION}`, httpMethods.POST, payload)

export const COUNTRY_LIST = (payload) => serviceMaker(`${apiEndPoints.COUNTRY_LIST}`, httpMethods.POST, payload)
export const COUNTRY_ADD = (payload) => serviceMaker(`${apiEndPoints.COUNTRY_ADD}`, httpMethods.POST, payload)
export const COUNTRY_EDIT = (payload) => serviceMaker(`${apiEndPoints.COUNTRY_EDIT}`, httpMethods.POST, payload)
export const COUNTRY_DELETE = (payload) => serviceMaker(`${apiEndPoints.COUNTRY_DELETE}`, httpMethods.POST, payload)
export const COUNTRY_ACTION = (payload) => serviceMaker(`${apiEndPoints.COUNTRY_ACTION}`, httpMethods.POST, payload)

export const ADMIN_STATICPAGE_LIST = (payload) => serviceMaker(`${apiEndPoints.ADMIN_STATICPAGE_LIST}`, httpMethods.POST, payload)
export const ADMIN_STATICPAGE_ADD = (payload) => serviceMaker(`${apiEndPoints.ADMIN_STATICPAGE_ADD}`, httpMethods.POST, payload)
export const ADMIN_STATICPAGE_EDIT = (payload) => serviceMaker(`${apiEndPoints.ADMIN_STATICPAGE_EDIT}`, httpMethods.POST, payload)
export const ADMIN_STATICPAGE_DELETE = (payload) => serviceMaker(`${apiEndPoints.ADMIN_STATICPAGE_DELETE}`, httpMethods.POST, payload)
export const ADMIN_STATICPAGE_ACTION = (payload) => serviceMaker(`${apiEndPoints.ADMIN_STATICPAGE_ACTION}`, httpMethods.POST, payload)

export const SATELLITE_LIST = (payload) => serviceMaker(`${apiEndPoints.SATELLITE_LIST}`, httpMethods.POST, payload)
export const SATELLITE_STATUS = (payload) => serviceMaker(`${apiEndPoints.SATELLITE_STATUS}`, httpMethods.POST, payload)


export const PAYMENT_METHOD_LIST = (payload) => serviceMaker(`${apiEndPoints.PAYMENT_METHOD_LIST}`, httpMethods.POST, payload)
export const PAYMENT_METHOD_ACTION = (payload) => serviceMaker(`${apiEndPoints.PAYMENT_METHOD_ACTION}`, httpMethods.POST, payload)
export const PAYMENT_METHOD_ADD = (payload) => serviceMaker(`${apiEndPoints.PAYMENT_METHOD_ADD}`, httpMethods.POST, payload)
export const PAYMENT_METHOD_EDIT = (payload) => serviceMaker(`${apiEndPoints.PAYMENT_METHOD_EDIT}`, httpMethods.POST, payload)
export const PAYMENT_METHOD_DELETE = (payload) => serviceMaker(`${apiEndPoints.PAYMENT_METHOD_DELETE}`, httpMethods.POST, payload)
export const PROPOSED_PAYMENTS_LIST = (payload) => serviceMaker(`${apiEndPoints.PROPOSED_PAYMENTS_LIST}`, httpMethods.POST, payload)
export const PROPOSED_PAYMENT_STATUS = (payload) => serviceMaker(`${apiEndPoints.PROPOSED_PAYMENT_STATUS}`, httpMethods.POST, payload)

export const IMAGE_UPLOAD = (payload) => addImageMaker(`${apiEndPoints.IMAGE_UPLOAD}`, httpMethods.POST, payload)
export const IMAGE_EDIT = (payload) => addImageMaker(`${apiEndPoints.IMAGE_EDIT}`, httpMethods.POST, payload)

export const SUPPORTDATA_LIST = (payload) => serviceMaker(`${apiEndPoints.SUPPORTDATA_LIST}`, httpMethods.POST, payload)
export const TRANSACTION_CANCEL = (payload) => serviceMaker(`${apiEndPoints.TRANSACTION_CANCEL}`, httpMethods.POST, payload)

export const SUPPORTDATA_DETAILS = (payload) => serviceMaker(`${apiEndPoints.SUPPORTDATA_DETAILS}`, httpMethods.POST, payload)
export const SUPPORTDATA_WITH_IMAGE = (payload) => serviceMaker(`${apiEndPoints.SUPPORTDATA_WITH_IMAGE}`, httpMethods.POST, payload)
export const SUPPORTDATA_RESOLVE_STATUS = (payload) => serviceMaker(`${apiEndPoints.SUPPORTDATA_RESOLVE_STATUS}`, httpMethods.POST, payload)

export const ADMIN_NOTIFICATION_List = (payload) => serviceMaker(`${apiEndPoints.ADMIN_NOTIFICATION_List}`, httpMethods.POST, payload)
export const ADMIN_UPDATE_NOTIFIAVTION = (payload) => serviceMaker(`${apiEndPoints.ADMIN_UPDATE_NOTIFIAVTION}`, httpMethods.POST, payload)

export const SYSTEM_CONFIG_CANCEL_TIME_GET = () => serviceMaker(`${apiEndPoints.SYSTEM_CONFIG_CANCEL_TIME_GET}`, httpMethods.GET)
export const SYSTEM_CONFIG_CANCEL_TIME_UPDATE = (payload) => serviceMaker(`${apiEndPoints.SYSTEM_CONFIG_CANCEL_TIME_UPDATE}`, httpMethods.POST, payload)

export const MOST_ACTIVE_SATELLITE_REPORT = (payload) => serviceMaker(`${apiEndPoints.MOST_ACTIVE_SATELLITE_REPORT}`, httpMethods.POST, payload)
export const MOST_ACTIVE_USERS_REPORT = (payload) => serviceMaker(`${apiEndPoints.MOST_ACTIVE_USERS_REPORT}`, httpMethods.POST, payload)
export const MOST_ACTIVE_PAYMENT_METHOD_REPORT = (payload) => serviceMaker(`${apiEndPoints.MOST_ACTIVE_PAYMENT_METHOD_REPORT}`, httpMethods.POST, payload)
export const MOST_ACTIVE_CURRENCY_REPORT = (payload) => serviceMaker(`${apiEndPoints.MOST_ACTIVE_CURRENCY_REPORT}`, httpMethods.POST, payload)
export const MOST_CHAT_TRASACTION_REPORT = (payload) => serviceMaker(`${apiEndPoints.MOST_CHAT_TRASACTION_REPORT}`, httpMethods.POST, payload)
